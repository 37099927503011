$.ajaxSetup({
  headers: {
    'X-XSRF-TOKEN': $.cookie('XSRF-TOKEN')
  }
});

$('.date-menu li').each(function () {
  $(this).click(function () {
    $('.date-input').val($(this).html());
    $('.date-menu').hide();
  });
});


function check(data, cb) {
  $.post('/api/check', data, function (err) {
    cb(err);
  });
}

function book(data, cb) {
  //console.log(data);
  $.post('/api/book', data, function (err) {
    //console.log(err);
    cb(err);
  });
}

function date_range(cb) {
  $.get('/api/date/range', function (list) {
    cb(list);
  });
}

function showMainSubscribe() {
  var name, phone, email, occupation, date;
  var $modal = $('<div class="subscribe-form">' +
    '<div class="subscribe-center">' +
    '<div class="shut-down"></div><label>MAKE APPOINTMENT 参观预约</label>' +
    '<hr>' +
    '<div id="main-error"></div>' +
    '<div class="form"><span>姓名 name</span><input id="main-input-name" class="name-input" type="text"></div>' +
    '<div class="form"><span>手机 mobile phone</span><input id="main-input-phone" class="name-input" type="text" placeholder="一个手机号对应一位预约人 / one mobile phone only used once"></div>' +
    '<div class="form"><span>邮箱 email</span><input id="main-input-email" class="name-input" type="text" placeholder="一个邮箱只能使用一次 / one email only used once"></div>' +
    '<div class="select"><span>职业 occupation</span>' +
    '<div class="button select-button" data-type="1"> 买手 buyer</div>' +
    '<div class="button select-button" data-type="2"> 品牌 brand</div>' +
    '<div class="button select-button" data-type="3"> 媒体 media</div>' +
    '<div class="button select-button" data-type="4"> 其他 other</div>' +
    '</div>' +
    '<div class="form date-form"><span>日期 date</span><input id="main-input-date" class="date-input" type="button" value="选择预约日期">' +
    '<div class="date-menu">' +
    '<ul>' +
    '</ul>' +
    '</div>' +
    '</div>' +
    '<div class="submit" href="#" title="title">' +
    '<font>提交 </font>' +
    '</br><span>submit</span></div>' +
    '</div>' +
    '</div>');
  date_range(function (list) {
    var ul = $modal.find('ul');
    for (var index = 0; index < list.length; index++) {
      var element = list[index];
      ul.append('<li>' + element + '</li>');
    }
    $date_menu.find('li').click(function () {
      date = $(this).text();
      $input_date.val($(this).text());
      $(this).toggleClass('show');
      $date_menu.toggleClass('show');
    });
  });
  $modal.find('.shut-down').click(function () {
    $modal.remove();
  });
  $modal.find('.select-button').click(function () {
    $(this).addClass('current').siblings().removeClass('current');
    occupation = $(this).data('type');
  });
  var $date_menu = $modal.find('.date-menu');

  var $err = $modal.find('#main-error');
  var $input_name = $modal.find('#main-input-name');
  var $input_phone = $modal.find('#main-input-phone');
  var $input_email = $modal.find('#main-input-email');
  var $input_date = $modal.find('#main-input-date');
  var $submit = $modal.find('.submit');
  $input_date.click(function () {
    $(this).toggleClass('show');
    $date_menu.toggleClass('show');
  });

  $submit.click(function () {
    name = $input_name.val();
    phone = $input_phone.val();
    email = $input_email.val();

    var data = {
      name: name,
      phone: phone,
      email: email,
      occupation: occupation,
      date: date
    };

    if (occupation === 1) {
      check(data, function (err) {
        if (err.length > 0) {
          $err.text(err[0]);
        } else {
          $modal.remove();
          showBuyerSubscribe(data);
        }
      });
    } else {
      book(data, function (err) {
        if (err.length > 0) {
          $err.text(err[0]);
        } else {
          $modal.remove();
          showSuccess();
        }
      });
    }
  });

  $('body').append($modal);
}

function showBuyerSubscribe(data) {
  var $modal = $('<div class="subscribe-form2">' +
    '<div class="subscribe-center2">' +
    '<div class="shut-down"></div><label>MAKE APPOINTMENT 参观预约</label>' +
    '<hr>' +
    '<div id="main-error"></div>' +
    '<div class="form">' +
    '<div class="form-text">' +
    '<font>买手店名称 </font>' +
    '</br><span>the name of select shop</span></div><input id="buyer-input-shop" class="name-input" type="text"></div>' +
    '<div class="form">' +
    '<div class="form-text">' +
    '<font>所在城市</font>' +
    '</br><span>city</span></div><input id="buyer-input-city" class="name-input" type="text"></div>' +
    '<div class="submit">' +
    '<font>提交 </font>' +
    '</br><span>submit</span></div>' +
    '</div>' +
    '</div>');

  var $err = $modal.find('#main-error');
  $modal.find('.shut-down').click(function () {
    $modal.remove();
  });
  $modal.find('.submit').click(function () {
    data.shop = $modal.find('#buyer-input-shop').val();
    data.city = $modal.find('#buyer-input-city').val();


    book(data, function (err) {
      if (err.length > 0) {
        $err.text(err[0]);
      } else {
        $modal.remove();
        showSuccess();
      }
    });
  });
  $('body').append($modal);
}

function showSuccess() {
  $modal = $('<div class="subscribe-form3">' +
    '<div class="subscribe-center3">' +
    '<div class="shut-down"></div>' +
    '<div class="succeed1">预约成功</div>' +
    '<div class="succeed2">预约确认函已发送至相关手机／邮箱，请妥善保存。The reservation confirmation has been sent to the relevant mobile phone/email. Please keep properly.' +
    '</div>' +
    '<div class="submit">' +
    '<font>继续预约 </font>' +
    '</br><span>add another reservation</span></div>' +
    '</div>' +
    '</div>');
  $modal.find('.shut-down').click(function () {
    $modal.remove();
  });
  $modal.find('.submit').click(function () {
    $modal.remove();
    showMainSubscribe();
  });
  $('body').append($modal);
}

$(".booking-button").click(function () {
  showMainSubscribe();
});